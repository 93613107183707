<template>
  <div class="min-h-screen bg-white flex">
    <div
      class="
        flex-1 flex flex-col
        justify-center
        py-12
        px-4
        sm:px-6
        lg:flex-none lg:px-20
        xl:px-24
      "
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img
            class="h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
            alt="Workflow"
          />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Login to your account
          </h2>
          <p class="mt-2 text-sm text-primary font-medium">
            Welcome back to AJ Admin Dasboard
          </p>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form @submit.prevent="handleLogin()" class="space-y-6">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="user.identifier"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    v-model="user.password"
                    autocomplete="current-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    class="
                      h-4
                      w-4
                      text-blue-600
                      focus:ring-blue-500
                      border-gray-300
                      rounded
                    "
                  />
                  <label
                    for="remember_me"
                    class="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  :class="[
                    `
                    w-full
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-light-blue-700
                    hover:bg-light-blue-800
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500`,
                    isLoading && 'cursor-not-allowed opacity-60',
                  ]"
                >
                  <svg
                    v-if="isLoading"
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="hidden lg:block relative w-0 flex-1">
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src="@/assets/img/form-sign.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { loginUser } from "@/services/auth";
import router from "@/router";

export default {
  setup() {
    const toast = useToast();
    const isLoading = ref(false);
    const user = ref({ identifier: "", password: "" });
    const store = useStore();
    const handleLogin = async () => {
      try {
        isLoading.value = true;
        const { data } = await loginUser(user.value);
        store.dispatch("user/setUser", data.user);
        router.push({
          name: data.user.role.name === "Client"? "updates" : data.user.role.name === "Staff" ? "staff-cases" :"dashboard",
        });
      } catch (e) {
        toast.error(e.response.data.message[0].messages[0].message);
        setTimeout(() => {
          isLoading.value = false;
        }, 500);
      }
    };

    return { user, handleLogin, isLoading };
  },
};
</script>